export default {
    head() {
        return {
            meta: [
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.$t('homepage.hero.fallback_title'),
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('homepage.hero.fallback_title'),
                },
                {
                    property: 'og:url',
                    content: this.$config.baseURL + this.$route.fullPath,
                },
            ],
        };
    },
};
