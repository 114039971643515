import genericUrls from '@/middleware/urls.json';

export default function ({ store, redirect, $cookies, $auth, getRouteBaseName, route }) {
    if (process.server) {
        const redirectUrl = genericUrls.data.find((data) => data.old === route.path);

        if (redirectUrl) {
            return redirect(301, redirectUrl.new);
        }
    }
    if (store.state.auth.loggedIn && !$cookies.get('__naturadent__keep_alive')) {
        $auth.logOut();
        return;
    }

    if (store.state.auth.loggedIn) {
        $cookies.set('__naturadent__keep_alive', true, {
            maxAge: $cookies.get('__naturadent__remember_me') ? 60 * 60 * 24 * 365 : 60 * 60 * 2,
        });
    }

    if (!getRouteBaseName()) {
        return;
    }

    if (store.state.auth.loggedIn && getRouteBaseName() === 'forgot-password') {
        return redirect('/');
    }

    if (!store.state.auth.loggedIn && getRouteBaseName().match(/^account-/)) {
        return redirect('/');
    }
}
