export default function ({ i18n, $axios, $config, $recaptcha, $dayjs, store }) {
    // This method will generate the localized route data for a given dynamic endpage and
    // dynamically add them to the i18n.pages object in nuxt.config.js.
    //
    // Since in this specific project there is no connection between the localized versions of the same dynamic endpage,
    // on dynamic endpages we'd see a 404 error page on locale change. To handle this case more elegantly, we want to
    // redirect the user to the current dynamic page's static collector page (e.g. from blog/_article to /blog) instead.
    // Given this blog article example, the article endpage will detect in its asyncData whether it should redirect to
    // its static collector page (/blog) by having '__redirect' passed as a value for the 'article' param.
    // After the following method gets called, given that the currently selected language is hungarian, the generated
    // config dynamically added to the i18n.pages object in nuxt.config.js will look like the following:
    //
    // pages: {
    //      ...
    //      'blog/_article': {
    //
    //      }
    // }
    //
    //

    i18n.generateLocalizedRouteParams = async function (model, paramName = 'slug', alwaysForceRedirect = false) {
        const _routeParamsPayload = {};

        i18n.locales.forEach((locale) => {
            _routeParamsPayload[locale.code] = {};
        });

        i18n.locales.forEach((locale) => {
            if (locale.code === i18n.locale && !alwaysForceRedirect) {
                _routeParamsPayload[locale.code][paramName] = model.slug;
            } else {
                _routeParamsPayload[locale.code][paramName] = '__redirect';
            }
        });

        await store.dispatch('i18n/setRouteParams', _routeParamsPayload);
    };
    // i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    //     console.log(oldLocale, newLocale, isInitialSetup)
    // }

    $recaptcha.language = i18n.locale;

    i18n.onLanguageSwitched = (oldLocale, newLocale) => {
        $axios.defaults.baseURL = $config.baseURL + '/api/' + newLocale;
        $dayjs.locale(newLocale);
        if (!store.state.data.services[newLocale].length) {
            store.dispatch('fetchEssentialData');
        }

        $recaptcha.language = newLocale;
    };
}
