var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"\n            text-[1.75rem]\n            font-HKGrotesk-SemiBold\n            text-center\n            mb-7\n            pb-4\n            border-b border-black border-opacity-10\n            block\n        "},[_c('T',{attrs:{"t":"generic.appointment_booking"}})],1),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('form',{staticClass:"w-full",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('ValidationProvider',{staticClass:"col-span-2 lg:col-span-1",attrs:{"vid":"last_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{staticClass:"p-input-label",attrs:{"for":"last-name"}},[_c('T',{attrs:{"t":"form.last_name"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"last-name","name":"last_name","type":"text","placeholder":_vm.$t('form.last_name_placeholder'),"autocomplete":"off"},on:{"input":function($event){return _vm.triggerGtagManager('last_name', _vm.last_name, 1)}},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2 lg:col-span-1",attrs:{"vid":"first_name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{staticClass:"p-input-label",attrs:{"for":"first-name"}},[_c('T',{attrs:{"t":"form.first_name"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"first-name","name":"first_name","type":"text","placeholder":_vm.$t('form.first_name_placeholder'),"autocomplete":"off"},on:{"input":function($event){return _vm.triggerGtagManager('first_name', _vm.first_name, 2)}},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2 lg:col-span-1",attrs:{"vid":"email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{staticClass:"p-input-label",attrs:{"for":"email"}},[_c('T',{attrs:{"t":"form.email"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"email","placeholder":_vm.$t('form.email'),"type":"email"},on:{"input":function($event){return _vm.triggerGtagManager('email', _vm.email, 3)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2 lg:col-span-1",attrs:{"vid":"phone","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{staticClass:"p-input-label",attrs:{"for":"phone"}},[_c('T',{attrs:{"t":"generic.telephone_number"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"phone","placeholder":_vm.$t('form.telephone_number_placeholder'),"type":"phone"},on:{"input":function($event){return _vm.triggerGtagManager('phone', _vm.phone, 4)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2 lg:col-span-1",attrs:{"vid":"service","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{staticClass:"p-input-label",attrs:{"for":"service"}},[_c('T',{attrs:{"t":"form.service"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"service","placeholder":_vm.$t('form.service_placeholder'),"type":"phone"},on:{"input":function($event){return _vm.triggerGtagManager('service', _vm.service, 5)}},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2 lg:col-span-1",attrs:{"vid":"date","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputLabel',{staticClass:"p-input-label",attrs:{"for":"date"}},[_c('T',{attrs:{"t":"form.date"}})],1),_vm._v(" "),_c('Calendar',{ref:"calendar",staticClass:"w-full",attrs:{"date-format":'mm/dd/yy',"autocomplete":"off","disabled-days":[0, 6, 7],"manual-input":false,"placeholder":_vm.$t('form.date_placeholder')},on:{"input":function($event){return _vm.triggerGtagManager('date', _vm.date, 6)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2 mt-2",attrs:{"vid":"accept_newsletter","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"p-field field-checkbox"},[_c('Checkbox',{attrs:{"id":"accept_newsletter","binary":true},model:{value:(_vm.accept_newsletter),callback:function ($$v) {_vm.accept_newsletter=$$v},expression:"accept_newsletter"}}),_vm._v(" "),_c('label',{attrs:{"for":"accept_newsletter"}},[_c('T',{attrs:{"t":"form.newsletter_checkbox_title"}})],1)],1),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"vid":"accept_privacy_policy","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"p-field field-checkbox"},[_c('Checkbox',{attrs:{"id":"accept_privacy_policy","binary":true},model:{value:(_vm.accept_privacy_policy),callback:function ($$v) {_vm.accept_privacy_policy=$$v},expression:"accept_privacy_policy"}}),_vm._v(" "),_c('label',{attrs:{"for":"accept_privacy_policy"}},[_c('T',{attrs:{"t":"form.data_privacy.text_1"}}),_vm._v(" "),_c('NuxtLink',{staticClass:"p-link",attrs:{"to":_vm.localePath('privacy-policy'),"target":"_blank","rel":"noopener noreferrer"}},[_c('T',{attrs:{"t":"form.data_privacy.text_2"}})],1),_vm._v(" "),_c('T',{attrs:{"t":"form.data_privacy.text_3"}}),_vm._v(" "),_c('NuxtLink',{staticClass:"p-link",attrs:{"to":_vm.localePath('terms-and-conditions'),"target":"_blank","rel":"noopener noreferrer"}},[_c('T',{attrs:{"t":"form.data_privacy.text_4"}})],1),_vm._v(" "),_c('T',{attrs:{"t":"form.data_privacy.text_5"}})],1)],1),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}])}),_vm._v(" "),_c('div',{staticClass:"col-span-2 text-left mt-2"},[_c('Button',{staticClass:"xs-w-full",attrs:{"type":"submit","disabled":!_vm.email.length ||
                                !_vm.date ||
                                !_vm.phone.length ||
                                !_vm.first_name.length ||
                                !_vm.last_name.length ||
                                !_vm.accept_privacy_policy ||
                                _vm.canBeSent,"label":_vm.$t('form.send_appointment_booking')}})],1),_vm._v(" "),_c('RecaptchaDisclaimer')],1)])]),_vm._v(" "),(_vm.isThankYouMessageVisible)?_c('div',{staticClass:"\n                absolute\n                top-0\n                -left-1\n                w-[calc(100%+4px))\n                h-[calc(100%+4px)]\n                bg-white bg-opacity-100\n                flex\n                items-center\n                ld:items-start\n                justify-center\n            "},[_c('div',[_c('span',{staticClass:"subtitle mb-4 text-dark-700"},[_c('T',{attrs:{"t":"form.booking_appointment_feedback_message_title"}})],1),_vm._v(" "),_c('div',{staticClass:"text-dark-200",domProps:{"innerHTML":_vm._s(_vm.$t('form.booking_appointment_feedback_message_desc'))}})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }