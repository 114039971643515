<template>
    <label class="mb-3 font-HKGrotesk-Bold flex items-center justify-between">
        <slot></slot>
        <span v-if="optional" class="text-sm font-HKGrotesk-Regular text-dark-500">(opcionális)</span>
    </label>
</template>

<script>
export default {
    name: 'InputLabel',
    props: {
        for: {
            type: String,
            required: true,
        },
        optional: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
