<template>
    <div id="__layout__content" class="flex flex-col min-h-screen">
        <Header v-if="displayFooterAndHeader" class="z-40" />

        <main>
            <Nuxt class="relative flex-grow pt-28" />
        </main>

        <Footer v-if="displayFooterAndHeader" class="relative lg:z-5" />

        <CookieBanner v-if="!$device.isCrawler" is-compact class="z-40" />

        <GridOverlay v-if="!$device.isCrawler" />

        <Toast v-if="!$device.isCrawler" position="top-right">
            <template #message="slotProps">
                <div>{{ slotProps.message.summary }}</div>
            </template>
        </Toast>

        <Portal v-if="!$device.isCrawler && false" selector="head">
            <script id="mcjs">
                !(function (c, h, i, m, p) {
                    (m = c.createElement(h)),
                        (p = c.getElementsByTagName(h)[0]),
                        (m.async = 1),
                        (m.src = i),
                        p.parentNode.insertBefore(m, p);
                })(
                    document,
                    'script',
                    'https://chimpstatic.com/mcjs-connected/js/users/b64995088e55e13cd1e028d01/ef8a1f4637ed153833fc9ce98.js'
                );
            </script>
        </Portal>
    </div>
</template>

<script>
import Toast from 'primevue/toast';
import Footer from '@/components/Layout/Footer';
import Header from '@/components/Layout/Header';
import GridOverlay from '@/components/Layout/GridOverlay';
import CookieBanner from '~/components/Layout/CookieBanner.vue';
import meta from '@/mixins/meta';

export default {
    name: 'Default',
    components: {
        Toast,
        Header,
        Footer,
        GridOverlay,
        CookieBanner,
    },
    mixins: [meta],
    computed: {
        displayFooterAndHeader() {
            return !/^invisalign-orthodontic-treatment___/.test(this.$route.name);
        },
    },
};
</script>
