<template>
    <div class="inline-flex items-center">
        <button
            class="uppercase px-2 mx-2"
            :class="$i18n.locale === 'hu' ? 'font-HKGrotesk-Bold text-dark-700' : 'text-dark-400'"
            @click="setLocale('hu')"
        >
            hun
        </button>
        <span class="w-1px h-5 bg-dark-700"></span>
        <button
            class="uppercase px-2 mx-2"
            :class="$i18n.locale === 'en' ? 'font-HKGrotesk-Bold text-dark-700' : 'text-dark-400'"
            @click="setLocale('en')"
        >
            eng
        </button>
    </div>
</template>

<script>
export default {
    name: 'LanguageSwitcher',
    methods: {
        setLocale(lngCode) {
            this.$i18n.setLocale(lngCode);
        },
    },
};
</script>

<style scoped></style>
