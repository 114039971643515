import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b886a06c = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7feb6bba = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _22c27758 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7fdb0fe2 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _76914b22 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _df58b132 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _bd3a1470 = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _19c4c98c = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _21374d57 = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _29852888 = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _4ef5bcbe = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _7bb9ec75 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _5515dd42 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _659b117d = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7df2ccdb = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _01d325a4 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _36e4751f = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _3ce79794 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _8dfab368 = () => interopDefault(import('../pages/text-content.vue' /* webpackChunkName: "pages/text-content" */))
const _3e20f12e = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _0de9fe90 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _2570b36e = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _254b474f = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _da9c9fe2 = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _54e72914 = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _3ebdc337 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _6cbf45e8 = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _b26d2aae = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _4550f37a = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _049656a0 = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _2eb7340a = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _c07dd784 = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _266df6fe = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _146b9a9e = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _3d1bbe11 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _2ae14ca2 = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _01b261be = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _526f99b2 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _00e298c5 = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _b886a06c,
    name: "privacy-policy___hu___default"
  }, {
    path: "/araink",
    component: _7feb6bba,
    name: "pricing___hu___default"
  }, {
    path: "/blog",
    component: _22c27758,
    name: "blog___hu___default"
  }, {
    path: "/elfelejtett-jelszo",
    component: _7fdb0fe2,
    name: "forgot-password___hu___default"
  }, {
    path: "/en",
    component: _76914b22,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _df58b132,
    name: "terms-and-conditions___hu___default"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _bd3a1470,
    name: "payment-methods___hu___default"
  }, {
    path: "/fogaszati-panaszok",
    component: _19c4c98c,
    name: "dental-problems___hu___default"
  }, {
    path: "/garancia",
    component: _21374d57,
    name: "quality-guarantee___hu___default"
  }, {
    path: "/hu",
    component: _76914b22,
    name: "index___hu"
  }, {
    path: "/idopontfoglalas",
    component: _29852888,
    name: "appointment-booking___hu___default"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _4ef5bcbe,
    name: "invisalign-orthodontic-treatment___hu___default"
  }, {
    path: "/jelszovaltoztatas",
    component: _7bb9ec75,
    name: "change-password___hu___default"
  }, {
    path: "/jogi-nyilatkozat",
    component: _5515dd42,
    name: "legal-notice___hu___default"
  }, {
    path: "/kapcsolat",
    component: _659b117d,
    name: "contact___hu___default"
  }, {
    path: "/kereses",
    component: _7df2ccdb,
    name: "search___hu___default"
  }, {
    path: "/leiratkozas",
    component: _01d325a4,
    name: "unsubscribe___hu___default"
  }, {
    path: "/munkaink",
    component: _36e4751f,
    name: "works___hu___default"
  }, {
    path: "/szolgaltatasok",
    component: _3ce79794,
    name: "services___hu___default"
  }, {
    path: "/text-content",
    component: _8dfab368,
    name: "text-content___hu___default"
  }, {
    path: "/en/appointment-booking",
    component: _29852888,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _22c27758,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _7bb9ec75,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _659b117d,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _19c4c98c,
    name: "dental-problems___en"
  }, {
    path: "/en/forgot-password",
    component: _7fdb0fe2,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _4ef5bcbe,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _5515dd42,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _bd3a1470,
    name: "payment-methods___en"
  }, {
    path: "/en/pricing",
    component: _7feb6bba,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _b886a06c,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _21374d57,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _7df2ccdb,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _3ce79794,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _df58b132,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/text-content",
    component: _8dfab368,
    name: "text-content___en"
  }, {
    path: "/en/unsubscribe",
    component: _01d325a4,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _36e4751f,
    name: "works___en"
  }, {
    path: "/hu/adatvedelmi-nyilatkozat",
    component: _b886a06c,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/araink",
    component: _7feb6bba,
    name: "pricing___hu"
  }, {
    path: "/hu/blog",
    component: _22c27758,
    name: "blog___hu"
  }, {
    path: "/hu/elfelejtett-jelszo",
    component: _7fdb0fe2,
    name: "forgot-password___hu"
  }, {
    path: "/hu/felhasznalasi-feltetelek",
    component: _df58b132,
    name: "terms-and-conditions___hu"
  }, {
    path: "/hu/fizetesi-lehetosegek",
    component: _bd3a1470,
    name: "payment-methods___hu"
  }, {
    path: "/hu/fogaszati-panaszok",
    component: _19c4c98c,
    name: "dental-problems___hu"
  }, {
    path: "/hu/garancia",
    component: _21374d57,
    name: "quality-guarantee___hu"
  }, {
    path: "/hu/idopontfoglalas",
    component: _29852888,
    name: "appointment-booking___hu"
  }, {
    path: "/hu/invisalign-fogszabalyozas",
    component: _4ef5bcbe,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/hu/jelszovaltoztatas",
    component: _7bb9ec75,
    name: "change-password___hu"
  }, {
    path: "/hu/jogi-nyilatkozat",
    component: _5515dd42,
    name: "legal-notice___hu"
  }, {
    path: "/hu/kapcsolat",
    component: _659b117d,
    name: "contact___hu"
  }, {
    path: "/hu/kereses",
    component: _7df2ccdb,
    name: "search___hu"
  }, {
    path: "/hu/leiratkozas",
    component: _01d325a4,
    name: "unsubscribe___hu"
  }, {
    path: "/hu/munkaink",
    component: _36e4751f,
    name: "works___hu"
  }, {
    path: "/hu/szolgaltatasok",
    component: _3ce79794,
    name: "services___hu"
  }, {
    path: "/hu/text-content",
    component: _8dfab368,
    name: "text-content___hu"
  }, {
    path: "/profilom/ajalnasok",
    component: _3e20f12e,
    name: "account-referral-program___hu___default"
  }, {
    path: "/profilom/beallitasok",
    component: _0de9fe90,
    name: "account-settings___hu___default"
  }, {
    path: "/profilom/idopontjaim",
    component: _2570b36e,
    name: "account-appointments___hu___default"
  }, {
    path: "/profilom/vezerlopult",
    component: _254b474f,
    name: "account-dashboard___hu___default"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _da9c9fe2,
    name: "about-introduction___hu___default"
  }, {
    path: "/rolunk/karrier",
    component: _54e72914,
    name: "about-career___hu___default"
  }, {
    path: "/rolunk/kepzeseink",
    component: _3ebdc337,
    name: "about-trainings___hu___default"
  }, {
    path: "/rolunk/orvosaink",
    component: _6cbf45e8,
    name: "about-dentists___hu___default"
  }, {
    path: "/rolunk/velemenyek",
    component: _b26d2aae,
    name: "about-testimonials___hu___default"
  }, {
    path: "/en/about/career",
    component: _54e72914,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _6cbf45e8,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _da9c9fe2,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _b26d2aae,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _3ebdc337,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _2570b36e,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _254b474f,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _3e20f12e,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _0de9fe90,
    name: "account-settings___en"
  }, {
    path: "/hu/profilom/ajalnasok",
    component: _3e20f12e,
    name: "account-referral-program___hu"
  }, {
    path: "/hu/profilom/beallitasok",
    component: _0de9fe90,
    name: "account-settings___hu"
  }, {
    path: "/hu/profilom/idopontjaim",
    component: _2570b36e,
    name: "account-appointments___hu"
  }, {
    path: "/hu/profilom/vezerlopult",
    component: _254b474f,
    name: "account-dashboard___hu"
  }, {
    path: "/hu/rolunk/bemutatkozas",
    component: _da9c9fe2,
    name: "about-introduction___hu"
  }, {
    path: "/hu/rolunk/karrier",
    component: _54e72914,
    name: "about-career___hu"
  }, {
    path: "/hu/rolunk/kepzeseink",
    component: _3ebdc337,
    name: "about-trainings___hu"
  }, {
    path: "/hu/rolunk/orvosaink",
    component: _6cbf45e8,
    name: "about-dentists___hu"
  }, {
    path: "/hu/rolunk/velemenyek",
    component: _b26d2aae,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _4550f37a,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _049656a0,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/hu/rolunk/karrier/preview/:job_preview",
    component: _4550f37a,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/hu/rolunk/kepzeseink/preview/:training_preview",
    component: _049656a0,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/en/about/career/:job",
    component: _2eb7340a,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _c07dd784,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _266df6fe,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _146b9a9e,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _3d1bbe11,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/hu/blog/preview/:article_preview",
    component: _3d1bbe11,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/hu/rolunk/ertesitesek/:notification",
    component: _266df6fe,
    name: "about-notifications-notification___hu"
  }, {
    path: "/hu/rolunk/karrier/:job",
    component: _2eb7340a,
    name: "about-career-job___hu"
  }, {
    path: "/hu/rolunk/kepzeseink/:training",
    component: _146b9a9e,
    name: "about-trainings-training___hu"
  }, {
    path: "/hu/rolunk/orvosaink/:dentist",
    component: _c07dd784,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _4550f37a,
    name: "about-career-preview-job_preview___hu___default"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _049656a0,
    name: "about-trainings-preview-training_preview___hu___default"
  }, {
    path: "/blog/preview/:article_preview",
    component: _3d1bbe11,
    name: "blog-preview-article_preview___hu___default"
  }, {
    path: "/en/about/:content",
    component: _2ae14ca2,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _01b261be,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _526f99b2,
    name: "services-service___en"
  }, {
    path: "/hu/blog/:article",
    component: _01b261be,
    name: "blog-article___hu"
  }, {
    path: "/hu/rolunk/:content",
    component: _2ae14ca2,
    name: "about-content___hu"
  }, {
    path: "/hu/szolgaltatasok/:service",
    component: _526f99b2,
    name: "services-service___hu"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _266df6fe,
    name: "about-notifications-notification___hu___default"
  }, {
    path: "/rolunk/karrier/:job",
    component: _2eb7340a,
    name: "about-career-job___hu___default"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _146b9a9e,
    name: "about-trainings-training___hu___default"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _c07dd784,
    name: "about-dentists-dentist___hu___default"
  }, {
    path: "/en/services/:service/:price",
    component: _00e298c5,
    name: "services-service-price___en"
  }, {
    path: "/hu/szolgaltatasok/:service/:price",
    component: _00e298c5,
    name: "services-service-price___hu"
  }, {
    path: "/blog/:article",
    component: _01b261be,
    name: "blog-article___hu___default"
  }, {
    path: "/rolunk/:content",
    component: _2ae14ca2,
    name: "about-content___hu___default"
  }, {
    path: "/szolgaltatasok/:service",
    component: _526f99b2,
    name: "services-service___hu___default"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _00e298c5,
    name: "services-service-price___hu___default"
  }, {
    path: "/",
    component: _76914b22,
    name: "index___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
